@charset "utf-8";
/* CSS Document */
body{background:#fff; color:#424242; font-size:15px; font-family: 'Inter', sans-serif; font-weight:400; line-height:24px; overflow-x:hidden;}
a, a:hover, a:focus, input:focus, select:focus, textarea:focus{text-decoration:none;outline:none;color:inherit;}
img{max-width:100%;}
ul, li, ol{margin:0;padding:0;list-style:none;}
h1, h2, h3, h4, h5, h6{margin:0; color:#3d3d3d; font-weight:500;font-family: 'Inter', sans-serif; }
h1{font-size:38px;}
h2{font-size:30px;}
h3{font-size:26px;}
h4{font-size:22px;}
h5{font-size:20px;}
h6{font-size:18px;}
p{margin:0; padding:0;}

label{font-weight:400;   color:#4a4a4a;  font-size: 14px;}


::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #ccc;
  font-weight:300; 
}
::-moz-placeholder { /* Firefox 19+ */
  color: #ccc;
   font-weight:300; 
}
:-ms-input-placeholder { /* IE 10+ */
  color: #ccc;
   font-weight:300; 
}
:-moz-placeholder { /* Firefox 18- */
  color: #ccc;
     font-weight:300; 
}

/* buttons */
.blue_btn{padding:6px 30px; background: #336bd7; color:#fff;	display:inline-block;border-radius:3px; transition:all ease 0.4s; -webkit-transition:all ease 0.4s;  font-weight:500; font-size:15px;border:none;}
.blue_btn:hover, .blue_btn:focus{ background: #2d82e2; color:#fff;}

/* inouts */
input, textarea, select { border: 1px solid #ccc;  border-radius: 5px;  width: 100%;  color: #333; padding: 9px 12px;  font-size: 15px;     height: 44px;}
input[type=checkbox], input[type=radio] { height: 15px;}


/************ main css ***************/ 

.container, #myCarousel{position:relative; z-index:999;}

/* navigation */
.navbar {padding:3px 0 0; margin:0;}
.navbar ul li a {color:#4a4a4a;  font-weight: 400;  font-size: 14px; transition:all ease 0.4s;}
.navbar .main_nav li a:hover, .navbar .main_nav li a:focus, .navbar .main_nav .active, .nav .open>a, .nav .open>a:focus, .nav .open>a:hover{background:transparent; color: #2fa6ea;}
.nav_btn li a {padding: 6px 13px; margin: 6px 0 0 11px; text-transform: none;}
.nav_btn li .white_btn{color:#f48027;}
.nav_btn li .green_btn:hover{background:#09b52f; color:#fff;}
.nav_btn li .stroke_btn:hover{color:#fff;}
.navbar-brand {height: auto; margin: 0 80px 0 0;}
.scrolled {background-color:#14163f; position: fixed !important;  transition: background-color 200ms linear; padding:1px 0;}
.dropdown ul li a {color: #333;}
.inner_nav{background:#122e48;}
.inner_nav .navbar {padding: 10px 0 0; margin:0;}
.inner_nav .navbar ul li a {padding: 10px 20px 22px;}
.inner_nav .navbar-nav {float: right;}
.nav>li>a:focus, .nav>li>a:hover { background-color: transparent;}
 ul.dropdown-menu li a {width: 100%;  padding: 5px 12px;}
.dropdown-menu>li>a:focus, .dropdown-menu>li>a:hover { color: #2fa6ea !important;   text-decoration: none;  background-color: transparent;}
.navbar-nav>li a .user_img {display: inline-block;  width: 22px;  height: 22px;  background: #ddd; border-radius: 50px; text-align: center;  margin-right: 4px;}
.inner_page .navbar ul li a.blue_btn {
    color: #fff;
    padding: 7px 25px;
    margin: 13px 0 0;
}
.inner_page .navbar ul li a.blue_btn.login_btn {
    border: 1px solid #333;
    background: transparent;
    color: #4e4e4e;
    margin-right: 10px;
    padding: 6px 25px;
}
.inner_page .navbar ul li a.blue_btn:hover{background:#2d82e2;}

.inner_page .navbar ul li a.blue_btn.login_btn:hover, .inner_page .navbar ul li a.blue_btn.login_btn:focus{color:#2d82e2; background:transparent; border-color:#2d82e2;}

/* home banner section */
#home_banner {
    padding: 6.5rem 0;
    /* // background: url(../images/graph_bg.png) no-repeat; */
    background-position: center bottom;
    background-size: 1000px;
}
.banner_img img {max-width: none;}
.banner_txt h1 {color: #fff; font-weight: 400; margin-bottom: 14px;}
.banner_txt .red_btn{margin:30px 0 0;}
.banner_txt {color: #fff; padding: 8rem 0 0 40px;}
.bottom_shape {position: absolute; left: 0; right: 0;  margin: 0 auto; bottom: 0;  width: 100%;}
#home_banner .owl-carousel .owl-item img{width:auto; max-width:100%;}
.top_slider {background: linear-gradient(100deg, rgba(2, 36, 132, 0.4), rgba(0, 0, 0, 0.6));  height: 404px;  overflow: hidden;}
.side_bx img { height: 109px; width: 100%; object-fit: cover;}
.banner_info {color:#424242; font-size: 21px;}
.banner_info .blue_btn {
    margin: 42px 0 10px;
    font-size: 19px;
    padding: 10px 30px;
}
.banner_img {
    position: relative;
}
.banner_img img {
    max-width: 38rem;
    margin: 0 0 80px;
    z-index: 2;
}
img.side_img {
    position: absolute;
    left: 0;
    max-width: 60rem;
    z-index: 1;
    top: 44px;
}
img.right_img {
    position: absolute;
    max-width: 60rem;
    right: 20px;
    z-index: 1;
	top: 44px;
}
.banner_info h1 {margin:0 0 20px;}


/* recommend section */
#recommend {margin: 6rem 0 0;}
.recommend_bx .info h6 img {width: 25px; margin-right: 5px;}
.recommend_bx .green_bg, .recommend_bx .red_bg {display: inline-block;float: right;  background: #06e1ef; padding: 5px 5px;  color: #fff; font-size: 14px;}
.recommend_bx .red_bg{background: #FF495F;}
.recommend_bx .info h6 {font-size: 16px;font-weight: 600;}
.recommend_bx .info {background: #fff; padding: 14px; box-shadow: 0 0 20px -7px rgba(0,0,0,0.2); border: 1px solid #eee;}
.heading{margin:0 0 20px}	
.recommend_bx .info strong {color: #333; margin-left: 33px;}
	
/* market table section start */
#market{padding:4rem 0 6rem;}
#market table tr td{ font-size: 14px; padding:20px 15px; vertical-align:middle;     color: #333;}
#market table tr td img {
    width: 22px;
    margin-right: 11px;
}
#market table tbody tr:hover{background: #edf5ff;  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08); cursor:pointer;}
#market table thead tr th {
    border: none;
    font-size: 13px;
    padding: 15px;
    color: #76808F;
    font-weight: 500;
}
#market .table {margin:0;}
#market .table-responsive {
    border: 1px solid #eee;
    margin: 0 0 15px;
    box-shadow: 0 0 20px rgba(0,0,0,0.08);
}
#market .green_clr, #market .red_clr  {font-weight: 500;}
#market table tr td:first-child {
    font-weight: 600;
}
.red_clr{color:#e80173 !important; font-weight: 400;}
.green_clr{color:#06e1ef !important; font-weight: 400;}
.home_table .nav-tabs { border: none;}
.home_table { margin-bottom: 15px;}
.home_table input { border-radius: 50px;}
.home_table .nav-tabs>li>a {
    border: none;
    font-weight: 600;
    padding: 10px 15px;
    color: #333;
    border: 1px solid #ddd;
    border-radius: 3px;
    margin-right: 16px;
    font-size: 14px;
}
.home_table .nav-tabs li.active a {
    color: #248ce5 !important;
    background: transparent !important;
    border: 1px solid #248ce5;
}
.bottom_link a {color: #0fa2f8;}
.table-striped>tbody>tr:nth-of-type(odd) {background-color: rgba(114, 145, 161, 0.08); }

/* customer service */
#service{background:#f3f8ff; padding:7rem 0;  font-size: 14px;}
#service .heading {margin: 0 0 3rem;}
.service_info a{color:#06C;}
.service_info h3 {font-weight: 600; font-size: 19px; margin: 2rem 0 8px;}
.service_info .white_bg{background: rgba(255,255,255,0.5); margin: 15px 0; padding: 20px 25px; border: 1px solid #eceff1; line-height: 22px; box-shadow: 0 0 9px rgba(0,0,0,0.05);     line-height: 26px; font-size: 15px;}
.service_info img {max-width: 65px;}

/* download app section */
#download_app {background: linear-gradient(to right, #113fa7, #0e90ff); color: #fff;  padding: 3rem 0;}
#download_app h2 { color: #fff; margin: 0 0 6px;}
#download_app .red_btn {
    color: #333;
    background: #fff;
    padding: 10px 30px;
    font-size: 18px;
    margin: 10px 0 0;
    display: inline-block;
}


#our_advantages{padding:6rem 0;}
.advantage_info h4 {
    font-size: 30px;
    margin:0 0 45px;
	position:relative;
}
.advantage_info h4:after {
    content: '';
    position: absolute;
    bottom: -22px;
    left: 0;
    width:60px;
    height: 3px;
    background:#336bd7;
}
.advantage_info {
    margin: 4rem 0;
    font-size: 16px;
	line-height: 30px;
}
.advantage_head {
    background: #f4ba2f;
    padding: 15px 0 10px;
	margin:0 0 40px;
}



/* footer start */
footer {padding: 5rem 0; font-size: 14px;  background: #f7f9fa;  color: #4e4e4e;  border-top: 1px solid #eee;}
footer .links h6, .connect_info h6 {margin: 0 0 15px;font-weight: 500; text-transform: none; font-size:20px;}
.connect_info ul li { font-size: 24px;  display: inline-block;  padding-right: 22px;}
.connect_info ul{padding:20px 0;}
.links ul li {
    padding: 3px 0;
    font-weight: 500;
    color: #666;
}
.links ul li a{transition:all ease 0.4s; -webkit-transition:all ease 0.4s; padding: 1px 0;}
.links ul li:hover a{color:#2fa6ea;}
.connect_info ul li:first-child a{color:#ff4500;}
.connect_info ul li:nth-child(2) a{color:#2499d3;}
.connect_info ul li:nth-child(3) a{color:#55acee;}
.connect_info ul li:nth-child(4) a{color:#3b5999;}
.connect_info ul li:nth-child(5) a{color:#02b875;}

/**************** inner pages  **************/

.inner_page .navbar {
    background: #f8f8ff;
    padding: 0;
    position: relative;
    border-radius: 0;
    box-shadow: 2px 1px 2px #eee;
    -webkit-box-shadow: 2px 1px 2px #eee;
}
.inner_page .navbar ul li a {
    padding: 20px;
}
.inner_page .navbar ul li .dropdown-menu li a {
    padding: 8px 15px;
}



/* advance exchange */
#advance_trade .trade_info {background: #234d8e; color: #fff; padding:12px 15px;}
#advance_trade .trade_right span {display: block;  font-size: 12px;  color: #848cc9;  font-weight: 600;  line-height: 14px;}
#advance_trade .trade_side_br .nav-tabs li { display: inline-block;  padding:0; font-size: 12px;font-weight: 700;}
#advance_trade .fade_clr {display: inline-block; color: #828dcd;}
#advance_trade .trade_left h6 {display: inline-block;  color: #fff;}
#advance_trade .trade_left {margin: 7px 0 0;}
#advance_trade .trade_left img {display: inline-block; margin: 0 5px 0 0;  max-width: 24px;  vertical-align: bottom;}
#advance_trade .scroller img {max-width:18px;}
#advance_trade .trade_side_br .scroller {overflow: auto;    position: relative; height: 43vh;   background: #222e46; padding:0;}
#advance_trade .trade_side_br .tab-content ul li span {display: inline-block;}
#advance_trade .trade_side_br .tab-content ul li small span {display: inline-block; color: #8f98a2;}
#advance_trade .trade_side_br ul li small {display: inline-block; line-height: 14px;  float: right;  margin: 3px 0 0;}
#advance_trade .trade_side_br .tab-content ul li h6 .fa{float:right;  font-size: 13px;   color: #aaa;}
#advance_trade .trade_side_br .tab-content ul li {font-size: 13px;  color: #ececec; font-weight: 600;  border-bottom: 1px solid #484f5c; padding: 8px 0;}
#advance_trade .trade_side_br .tab-content ul li h6 {color: #ececec;   font-size: 13px; font-weight: 600;}
#advance_trade .trade_search input {background: transparent; padding: 0 10px;  border-radius: 50px;  font-size: 12px;  border: 1px solid #6b7281;}
#advance_trade .trade_side_br .nav-tabs {border-bottom: none; padding: 0 10px;}
#advance_trade .trade_side_br .trade_search {background: #37415a; padding: 10px;}
#advance_trade .trade_side_br{background:#222e46;}
#advance_trade .trade_right ul li {display: inline-block; padding: 0 10px;  font-size: 13px;  font-weight: 600;}
#advance_trade .trade_side_br .nav-tabs>li.active>a, #advance_trade .trade_side_br .nav-tabs>li.active>a:focus, #advance_trade .trade_side_br .nav-tabs>li.active>a:hover, #advance_trade .trade_side_br .nav-tabs>li a:focus {color: #6da6ff;  background-color: transparent;  border: none;  outline: none;}
#advance_trade .trade_side_br .nav-tabs>li a:hover{border:none;}
#advance_trade .trade_side_br .nav-tabs li a { padding: 11px 3px; border:none; color:#939fb0;}
#advance_trade .trade_side_br h6{color: #fff; font-weight: 500; font-size: 17px; margin: 10px 0 0;}
#advance_trade .dark_map {padding: 0;}
#advance_trade .all_price h6 {font-size: 15px; padding: 9px 6px; color: #fff;}
#advance_trade .right_trade .order_list table thead{background:#181a1d;}
#advance_trade .trade_his .default_amount {height: 45vh;}
.no_padding{padding:0;}
#advance_trade .default_amount table tr td, .default_volume table tr td {color: #b5bfcd;}
#advance_trade .all_price table tr td {
    font-size: 12px;
    text-align: left;
    border: none;
    padding: 4px 8px;
	color:#b5bfcd;
}
.all_price .nav-tabs {
    background: #181a1d;
    border: none;
}

#advance_trade .default_amount,#advance_trade  .default_volume { height: 19vh;}
#advance_trade .order_list h6, #advance_trade .trade_form_head h6  {display: inline-block; color: #fff; font-size: 16px;}
#advance_trade .order_list .nav-tabs>li.active>a, .order_list .nav-tabs>li.active>a:focus, .order_list .nav-tabs>li.active>a:hover, #advance_trade .trade_form_head .nav li.active a, #advance_trade .trade_form_head .nav li a:focus{ color: #6da6ff; background-color: transparent; border-bottom: 2px solid #6da6ff;}
#advance_trade .order_list .nav-tabs { background: transparent;  border: none;}
#advance_trade .order_list .nav>li>a, #advance_trade .trade_form_head .nav li a {color: #fff; border: none; position: relative;  display: block; padding: 13px 15px;
    font-size: 13px; font-weight: 500;  text-transform: none;   border-bottom: 2px solid transparent;}
#advance_trade .order_list .nav>li>a:hover, #advance_trade .trade_form_head .nav li a:hover,  #advance_trade .order_list .nav li.active a:hover{border:none;    border-bottom: 2px solid transparent;   background: transparent; color:#6da6ff;}
#advance_trade .order_list table td, #advance_trade .order_list table th {font-size: 12px;  font-weight: 400;  border: none; padding:10px 10px;}
#advance_trade .order_list table td {border-bottom:1px solid #353338; padding: 8px; color: #ececec;  vertical-align: middle;}
#advance_trade .order_list_head { background: transparent;    border-bottom: 1px solid #1a1c23;  padding: 0;}
#advance_trade #side_bar .order_list {border: 1px solid #262a35; background: rgb(28, 34, 38);  height: 93vh;  position: relative;}
#advance_trade .dark_map iframe {height: 45vh;}
#advance_trade .trade_form_head .nav-tabs {  border: none;}
#advance_trade .trade_buy_form h6 { margin: 0 -15px 11px;  padding: 7px 0;  font-size: 14px;   background: #222e46;   text-align: center;}
#advance_trade .trade_buy_form input {background: rgba(133, 133, 133, 0.1); padding: 2px 9px;  font-size: 12px;  border: 1px solid #4b586b;}
#advance_trade .trade_buy_form .form-group {margin-bottom: 10px;}
#advance_trade .amount_opt ul li {display: inline-block;  border: 1px dashed #455264; border-radius: inherit; padding: 0 8px; color: #818ea0; margin: 10px 4px 0;font-size: 12px;}
#advance_trade .trade_buy_form .btn {width: 100%; padding: 3px 5px;}
#advance_trade .trade_buy_form .btn:hover{color:#fff;} 
#advance_trade .trade_form {border-top: 2px solid #0a1525;}
#advance_trade .all_price {background:rgb(28, 34, 38);}
#advance_trade .order_list .scroller {padding: 0;  height: 49vh;}
#advance_trade .trade_form {height: 33vh; overflow:auto;  border-left: 2px solid #0a1525;}
#advance_trade .table-responsive{border:none;}
#advance_trade .red_clr {
    color: #ff227e !important;
    font-weight: 500 !important;
}
#advance_trade .green_clr{color:#06e1ef  !important; font-weight: 500 !important;}
#advance_trade .nav>li>a {padding: 7px 15px;}
#advance_trade .nav>li>a {padding:7px 9px; font-size: 13px; color: #8b939f;	text-transform:uppercase;font-weight:600;border:none;}
#advance_trade .nav-tabs>li.active>a, #advance_trade .nav-tabs>li.active>a:focus, #advance_trade .nav-tabs>li.active>a:hover {color: #6da6ff; cursor: default; background-color:transparent !important;  }
#advance_trade .scroller{padding:0;}
#advance_trade .transaction_bx {height: 70vh; overflow: auto;}
#advance_trade  .nav>li>a:focus,  #advance_trade  .nav>li>a:hover {background-color:transparent; border:none;}
#advance_trade .trade_side_br .scroller, #advance_trade .default_amount, #advance_trade .default_volume, #advance_trade .transaction_bx, #advance_trade .trade_form {scrollbar-width: thin;  scrollbar-color:#56697E transparent;}
#advance_trade .trade_side_br .tab-content .bx_space {padding: 0 10px;}
#advance_trade .right_trade .order_list table thead tr th {
    font-weight: 500;
}

.trade_nav .navbar-nav>li a {display: inline-block;}
.trade_nav .nav>li>a:focus, .trade_nav .nav>li>a:hover, .trade_nav .nav>li.active a { background-color: transparent; color: #2fa6ea !important;}
#side_bar {padding: 0;}
.left_space{padding-left:0;}
#advance_trade #side_bar .order_list .nav>li>a { border: 1px solid #42454e; border-radius: 3px; padding: 3px 10px; font-size: 15px; margin-right: 7px;color: #fff;}
.search_part {padding: 10px 15px;}
#side_bar .pagination_part {position: fixed; bottom: -5px; border-top: 1px solid #25282b; width: 33.3%; background: #181a1d;}
#side_bar .pagination_part .pagination {margin: 0; border-radius:0;}
#side_bar .pagination_part .pagination li {display: inline-block;}
#side_bar .pagination_part .pagination li a {border: none; border-right:1px solid #2d2d2d; border-radius: 0;  text-align: center;  padding: 10px 22px; font-size: 13px; color: #909090;margin-left: -4px; background: transparent;}
.map_part #tradingview_30698 div {width: 100% !important;  height: 48vh !important;}
#news_tab ul li .table_news {display: inline-flex; align-items: start;  vertical-align: middle;}
#news_tab ul li {padding: 12px 15px; border-top:1px solid #1f1d23;}
#news_tab ul li .table_news img {width: 274px; height: 90px; border-radius: 3px; object-fit: cover;}
#news_tab ul li .table_news .news_inner {margin-left: 15px;}
#news_tab ul li .table_news .news_inner h5 {font-size: 17px;text-transform: none;  line-height: 23px;  margin-bottom: 10px;	color:#ececec;}
#advance_trade #side_bar .order_list .nav>li>a.buy_link { background: #06e1ef; color:#fff !important;}
#advance_trade #side_bar .order_list .nav>li>a.sell_link {background: #e80173; color: #fff !important;}
.fixed_screen {height: 100vh; position: fixed; width: 100%;}
.fixed_screen #advance_trade {height: 93vh;}
.search_part .form-control {background: transparent; border: 1px solid #3d3b4c;}
.right_trade .pagination_part {justify-content: space-between; padding: 0 10px;  font-size: 13px;  color: #909090;  background:#181a1d; border-top: 1px solid #25282b;  position: fixed;  bottom: -5px; width: 66.5%;}
.right_trade .pagination_part p {display: inline-block; vertical-align: top;  margin: 10px 0 0;}
.right_trade .pagination_part ul {margin: 0; float: right;}
.right_trade .pagination_part ul li{display:inline-block;}
.right_trade .pagination_part .pagination li a{border: none; border-right: 1px solid #2d2d2d;  border-radius: 0;  text-align: center;  padding: 10px 22px;  font-size: 13px;  color: #909090;	margin-left: -3px;     background: transparent;}
#advance_trade #side_bar .order_list .tab-pane {height: 80vh; overflow: auto;}
#advance_trade #side_bar .order_list_head {   border-bottom: 1px solid #1f1d23;  padding: 13px 15px;}
#advance_trade .order_list table td.date_part {background: #08090c;   padding:7px 10px !important;}
#advance_trade #side_bar .order_list #calendar_tab {height: 85vh;}
#advance_trade .right_trade .order_list .table-responsive {height: 32vh;}
#advance_trade .right_trade .order_list { height: 44vh; position: relative;  border: 1px solid #262a35;  background:rgb(28, 34, 38);}
#advance_trade .order_list table thead tr th {font-weight: 600;}
#advance_trade #side_bar .order_list table td img {width: 30px;}
.trade_nav .navbar-nav>li a, .trade_nav .navbar-nav>li span {display: inline-block; font-size: 13px; color: #ececec; }
.trade_nav .navbar-nav>li a {
    padding: 8px  !important;
}
.trade_nav .main_nav li a {
    padding: 14px 10px !important;
}
.trade_nav .navbar-nav>li span strong {margin-left: 5px;}
#panels .collapse.in {
    display: table-cell;
    box-shadow: 0 1px 10px rgba(0,0,0,0.1);
}
#advance_trade .order_list table td .market_d img {
    max-width: 20px !important;
}
#advance_trade .order_list table td .market_d {
    border: 1px solid #ccc;
    padding: 5px 5px;
    border-radius: 3px;
    margin: 0 2px;
}
#panels .market_table table tr th {
    font-size: 10px;
    font-weight: 600;
    color: #8e8e8e;
    padding: 4px 10px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}
.market_d_info ul li {
    color: #9b9b9b;
    padding: 2px 0;
}
.market_d_info ul li span {
    float: right;
    color: #4e4e4e;
}
.min_left{padding-left:8px;}
.min_right{padding-right:8px;}
.market_d_info {
    padding: 15px 25px 6px;
    margin-top: 5px;
}
#panels .line {
    border: 0.5px solid #eee;
    margin: 15px 22px 0;
}

#panels .market_table table tr td {padding: 4px 10px; border: none !important;}
#panels .market_table table {width: 100%;}
.trade_nav.inner_page .navbar {background:rgb(38, 45, 51);}
#advance_trade #side_bar .order_list #panels {height: 95vh;  overflow: auto;}
#advance_trade .order_list table td .buy_link, #advance_trade .order_list table td .sell_link{ padding: 4px 10px;  border-radius: 3px;  display: inline-block;    color: #fff;  font-weight: 600;  margin: 0 3px;}
#advance_trade .order_list table td .buy_link{background:#06e1ef;}
#advance_trade .order_list table td .sell_link{background:#e80173;}
#advance_trade .order_list table thead{border-bottom: 1px solid #1f1d23; }
.all_price table {
    margin: 0 0 5px;
}
.all_price table tr th {
    font-size: 12px;
    padding: 6px 6px;
    border: none;
	color: #7a7a7a;
}
#advance_trade .default_amount, #advance_trade .default_volume {
    height: 31vh;
	overflow:auto;
}
#advance_trade .center_price {
    margin: 10px 0;
    padding: 5px 10px;
    font-size: 18px;
    border: none;
    background: rgb(38, 45, 51);
    color: #fff;
}
.buy_btn, .sell_btn{display:inline-block;  padding: 8px 30px;
    font-size: 16px;
    color: #fff;
	text-align:center;
	width:100%;
	margin:4px 0;
}
.buy_btn{background:#06e1ef;}
.sell_btn{background:#e80173;}
.buy_sell {
    padding: 0 10px;
}


/* dark mode */
.dark_mode {
    background: rgb(28, 34, 38);
}
.dark_mode .trade_nav ul.dropdown-menu {background: rgb(38, 45, 51);}
.dark_mode .popup_form .modal-content {border: 1px solid #30333a; -webkit-box-shadow: 0 5px 15px rgba(0,0,0,.6);  box-shadow: 0 5px 15px rgba(0,0,0,.6);    background: #17181b;}
.dark_mode .popup_form label {border-left: 5px solid #17181b; background: #17181b; border-right: 5px solid #17181b; color: #fff;}
.dark_mode .popup_form input, .dark_mode .popup_form select {color: #fff; border-color: #5d5d5d;}
.dark_mode .popup_form .stroke_btn {color: #ffffff;}
.dark_mode .popup_form select option{background:#17181b; font-size:12px;}
.dark_mode #advance_trade .order_list table thead tr th {
    color: #7a7a7a;
}
.dark_mode .market_d_info ul li {color: #e1e1e1;}
.dark_mode .market_d_info ul li span {color: #fff;}
.dark_mode #panels .line {border:0.5px solid #3a3a3a;}



/* light mode */
.light_mode #advance_trade .right_trade .order_list table thead tr th {font-weight: 600;  padding: 10px 15px; background: #f1f1f1; vertical-align: middle;}
.light_mode .inner_page .navbar {background: #fff; border: none;  box-shadow: 0 0 6px rgba(0,0,0,0.3);  z-index: 9;}
.light_mode .trade_nav .navbar-nav>li a, .light_mode .trade_nav .navbar-nav>li span  {color: #4a4a4a;}
.light_mode #advance_trade{background:#f9f9f9;}
.light_mode #advance_trade #side_bar .order_list {border: 1px solid #ddd; background: #fff;}
.light_mode #advance_trade .order_list table td {color: #4a4a4a; border-bottom: 1px solid #ececec; }
.light_mode #advance_trade .order_list table thead {background: #fff; color: #4a4a4a;  border-bottom: 1px solid #ececec;}
.light_mode #side_bar .pagination_part { border-top: 1px solid #ddd;  background: #fff;}
.light_mode #advance_trade #side_bar .order_list_head {background: #fff;  border-bottom: 1px solid #ececec;  padding: 13px 15px;}
.light_mode #advance_trade .order_list table td.date_part {background: #f1f1f1;}
.light_mode #advance_trade .order_list_head {background: #fff;  border-bottom: 1px solid #ddd;  padding: 0;}
.light_mode #advance_trade .order_list table td img {max-width: 30px;}
.light_mode #advance_trade .right_trade .order_list {background: #fff;  border: 1px solid #ddd;}
.light_mode #advance_trade #side_bar .order_list .nav>li>a {border: 1px solid #ddd; color:#333333;}
.light_mode .search_part .form-control {border: 1px solid #ddd;}
.light_mode #news_tab ul li .table_news .news_inner h5{color: #4a4a4a;}
.light_mode #news_tab ul li, .light_mode #advance_trade .order_list_head{border-top:1px solid #ddd;}
.light_mode #advance_trade #side_bar .order_list_head{border-color:#ececec;}
.light_mode #advance_trade .order_list .nav>li>a, .light_mode #advance_trade .trade_form_head .nav li a {color:#333;}
.light_mode #advance_trade .right_trade .order_list table thead{background:#222e46;}
.light_mode #side_bar .pagination_part .pagination li a, .light_mode .right_trade .pagination_part, .light_mode .right_trade .pagination_part .pagination li a {border-color:#ddd; background:#fff;}
.light_mode #advance_trade .all_price {background: #fff;}
.light_mode #advance_trade .all_price table tr td {color: #4a4a4a;}
.light_mode #advance_trade .center_price, .light_mode .all_price .nav-tabs{background: #f1f1f1;}

/* buy sell form */
#buy_modal .modal-header {background: #06e1ef; border-radius: 4px 4px 0 0; margin-right: -0.5px; border: none;  margin-top: -1px;}
#sell_modal .modal-header { background: #e80173; border-radius: 4px 4px 0 0; margin-right: -0.5px;  border: none;  margin-top: -1px;}
.popup_form .modal-header h5 {color: #fff; font-weight: 600; display: inline-block;}
.popup_form .checkbox_field input {width: auto; vertical-align: sub;}
.popup_form .checkbox_field {display: inline-block; padding-right: 29px;}
.popup_form .green_btn, .popup_form .red_btn {padding: 8px 30px; font-size: 16px; color:#fff;}
.popup_form .green_btn{background:#06e1ef;}
.popup_form .red_btn{background:#e80173;}
.popup_form .stroke_btn {border:1px solid #b4b4b4;  display: inline-block; padding: 7px 20px; border-radius: 3px; font-size: 16px; margin-left: 10px;color:#4a4a4a;}
.popup_form { width: 600px; left: 32%; bottom: -28px; height: auto;  top: auto;    position: absolute;}
.popup_form label {position: absolute; top: -11px;  left: 25px;  border-left: 5px solid #fff; background: #fff;  font-size: 13px;  border-right: 5px solid #fff;}
.popup_form input, .popup_form select {font-size: 14px; padding: 12px 10px 10px;background: transparent;}
.popup_form .form-group {padding: 0 10px; margin: 11px 0;}
.popup_form .checkbox_field label { position: relative; left: 0; top: 0; font-weight: 600;}
.popup_form .modal-body {padding: 15px 20px 5px;}
.popup_form .modal-dialog {width: 600px; padding: 12px;}
.popup_form .modal-content {order: 1px solid #ccc; -webkit-box-shadow: 0 5px 15px rgba(0,0,0,.3);  box-shadow: 0 5px 15px rgba(0,0,0,.3);}


/* width */
#advance_trade .default_amount::-webkit-scrollbar, #advance_trade .default_volume::-webkit-scrollbar, #advance_trade .transaction_bx::-webkit-scrollbar, #advance_trade .trade_form::-webkit-scrollbar, #advance_trade .trade_side_br .scroller::-webkit-scrollbar, #advance_trade #side_bar .order_list #panels::-webkit-scrollbar, #advance_trade .right_trade .order_list .table-responsive::-webkit-scrollbar{width:5px;}

/* Track */
#advance_trade .default_amount::-webkit-scrollbar-track, #advance_trade .default_volume::-webkit-scrollbar-track, #advance_trade .transaction_bx::-webkit-scrollbar-track, #advance_trade .trade_form::-webkit-scrollbar-track, #advance_trade .trade_side_br .scroller::-webkit-scrollbar-track{background:transparent; }
 
/* Handle */
#advance_trade .default_amount::-webkit-scrollbar-thumb, #advance_trade .default_volume::-webkit-scrollbar-thumb, #advance_trade .transaction_bx::-webkit-scrollbar-thumb, #advance_trade .trade_form::-webkit-scrollbar-thumb, #advance_trade .trade_side_br .scroller::-webkit-scrollbar-thumb, #advance_trade #side_bar .order_list #panels::-webkit-scrollbar-thumb, #advance_trade .right_trade .order_list .table-responsive::-webkit-scrollbar-thumb{background: #56697E; }

.light_mode #advance_trade #side_bar .order_list #panels::-webkit-scrollbar-thumb, .light_mode #advance_trade .default_amount::-webkit-scrollbar-thumb, .light_mode #advance_trade .default_volume::-webkit-scrollbar-thumb, .light_mode #advance_trade .right_trade .order_list .table-responsive::-webkit-scrollbar-thumb{background: #ddd; }

/* Handle on hover */
#advance_trade .transaction_bx::-webkit-scrollbar-thumb:hover, #advance_trade .trade_form::-webkit-scrollbar-thumb:hover, #advance_trade .trade_side_br::-webkit-scrollbar-thumb{background:transparent; }

/** login page */
.faded_bg{background:#f9f9f9;}
.account_form{background: #fff; padding: 40px 50px; border-radius: 5px; border: 2px solid #eee; box-shadow: 1px 1px 4px #eee; width: 420px; margin:80px auto 20px;}
.account_form .form-control {border: 1px solid #e0e0e0; background: transparent; height: 46px;color: #4a4a4a; box-shadow: none;}
.account_form .form-control:focus{border-color:#9b9b9b;}
.account_btns .blue_btn {width: 100%; margin: 10px 0;}
.account_txt {margin: 0 0 40px;}
.account_txt h2 {font-size: 30px;}
#account_form a:hover{color:#333;  transition:all ease 0.4s; -webkit-transition:all ease 0.4s;}

/* my assets pages */
#middel_area{padding:30px 0; background:#fafafb;}
#inner_banner a{margin:20px 0 0;}
#inner_banner p{color:#ccc;}
.area_right h4, .area_left h4, .bank_table h4{margin:0 0 20px;}
.area_right table tbody tr td .stroke_btn_green, .area_right table tbody tr td .stroke_btn_red {font-size: 13px;  padding: 5px 10px;  border-radius: 3px; transition:all ease 0.4s; -webkit-transition:all ease 0.4s; margin:2px;}
.area_right table tbody tr td .stroke_btn_green{border: 1px solid #09b52f;  color: #09b52f;}
.area_right table tbody tr td .stroke_btn_red {border: 1px solid #F00;  color: #F00;}
.area_right table tbody tr td .stroke_btn_green:hover, .area_right table tbody tr td .stroke_btn_red:hover{background:#fff;} 
.area_left .table tr td span{display:block; font-size:13px;}
.area_left .table tr td{font-size: 14px;  vertical-align: middle;  padding: 8px 15px;}
.side_bg {
    background: #fff;
    border-radius: 3px;
    border: 1px solid #eee;
}
.side_bg h6 {background: #0b3f70;  padding: 10px 15px; font-size:15px; color:#fff; font-weight: 300;}
.side_bg ul li { padding:8px 15px; transition:all ease 0.4s; -webkit-transition:all ease 0.4s; border-bottom: 1px solid #eee;}
.side_bg ul li a { font-size:14px; text-transform: capitalize; display:block;}
.side_bg ul li:hover, .side_bg ul li.active {color: #4c5cd1; border-right: 5px solid #4c5cd1;  background:rgba(76, 92, 209, 0.1);}
.side_txt span {float: right; color: #09b52f;}
.mid_btn {margin: 0 0 15px;}
.white_bx{box-shadow: 0 0 9px -2px rgba(0,0,0,0.1); border-radius:3px; background: #fff;  padding: 20px; margin:0 0 20px; border:1px solid #eee;}
.white_bx h6 {border-bottom: 1px solid #eee; margin: 0 -20px 12px; padding: 0 20px 18px;     color: #0372b6;}
#middel_area .table thead, .inner_table table thead{background: #f3f5f6;}
#middel_area .table thead tr th {
    color: #939393;
    border: none;
    font-size: 13px !important;
    font-weight: 600;
}
#middel_area .table thead tr th, #middel_area .table tbody tr td {
    padding: 12px 10px;
    vertical-align: middle;
    font-size: 13px;
}
#middel_area .table tbody tr td img { margin-right: 5px;}
#middel_area  .table-hover>tbody>tr:hover {background: rgba(14, 117, 238, 0.05);    transition: all ease 0.4s;}
.table-striped>tbody>tr:nth-of-type(odd) { background-color: #F0F5F7;}

/* my order page */
#middel_area .nav-tabs>li a{ background-color:rgba(14, 117, 238, 0.05);   border: 1px solid #e4f0f1;border-radius:3px; font-size:14px; color:#333;}
#middel_area .nav-tabs>li{margin-right:5px;}
#middel_area .nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, #middel_area  .nav-tabs>li.active>a:hover, #middel_area  .nav>li>a:focus, #middel_area  .nav>li>a:hover {color: #fff;  cursor: pointer;  background-color:  #2fa6ea;  border: 1px solid #2fa6ea;border-radius:3px;}
#middel_area  .nav-tabs {border: none; background: #fff; margin: 0 0 20px;}
#middel_area .pagination_part{border-top:1px solid #ddd;}
#middel_area .pagination_part .pagination{ margin-bottom:0;     font-size: 11px;}

/* deposit and withdrwal page */
#dLabel {width: 240px;height: 40px;  border-radius: 4px;  background-color: #fff; border: solid 1px #cccccc; text-align: left; padding: 7.5px 15px;  width: 100%;}
.deposit_balance_info {background: #4786fa; color: #fff; padding: 15px; border-radius: 3px;}
.deposit_part .caret {float: right;  margin-top: 9px; display: block !important;}
.deposit_part .dropdown-menu {width: 240px;  padding: 0; margin: 0;border-top-left-radius: 0;  border-top-right-radius: 0;}
.deposit_part .dropdown button:hover, .deposit_part .dropdown button:focus {border: none;outline: 0;}
.deposit_part .dropdown.open button#dLabel{border-bottom-left-radius: 0; border-bottom-right-radius: 0; border: solid 1px #13a1f7; border-bottom: none;  box-shadow: none;}
.deposit_part .dropdown.open ul {box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.23); height: 200px;overflow-y: scroll;width: 100%; background: #fff; border-top: 1px solid #13a1f7;}
.deposit_part .dropdown-menu li {line-height: 1.5; color: #666; font-size: 14px; cursor: pointer; padding: 7.5px 15px;}
.deposit_part .dropdown-menu li:hover {background-color:rgba(76, 92, 209, 0.06);}
.deposit_detail_bx {background: rgba(76, 92, 209, 0.06); margin: 20px 0 0; padding: 15px; border: 1px solid #eee;}
.deposit_detail_bx h5 {margin: 0 0 10px;  border-bottom: 1px solid #ddd; padding: 0 0 10px; font-size: 15px;}
.address_info p {word-break: break-all; font-size: 14px;color: #333;}
.address_info .red_btn {margin: 15px 5px 0 0; padding: 6px 15px;}
.deposit_balance_info ul li {font-size: 13px;}
.deposit_balance_info ul li span {float: right; font-weight: 600;     font-size: 12px;}
.no_data .fa {font-size: 30px; display: block; margin:20px 0 10px;}
.address_field label {display: block;}
.address_field label span {float: right; color: #333;}
.address_info .blue_btn {margin: 15px 5px 0 0; padding: 6px 15px;}
.deposit_detail_bx .blue_btn {text-align:center;}
.change_pass { margin: 20px 0 10px;}
.kyc_modal .modal-dialog {
    width: 350px;
}

/* dashboard page */
#middel_area .table tbody tr td a {font-weight: 600; color: #333;}
#middel_area .table tbody tr td .outline-btn {
    border: 1px solid #ccc;
    padding: 5px 10px;
    font-size: 13px;
    font-weight: 500;
    color: #3990db;
    border-radius: 3px;
}
#middel_area .table tbody tr td .outline-btn:hover{ border: 1px solid #3990db; background:#fff;}
#middel_area .table tbody tr td .name {font-size: 12px; color: #747474; font-weight: 400;}
#middel_area .assets_part .light_bg {display: flex;}
#middel_area .assets_part {margin: 0 0 23px;}
#middel_area .assets_part .dash_icon{margin-right:10px; margin-bottom: 0;}
#middel_area .light_bg {background: rgba(76, 92, 209, 0.06); padding: 15px;     border-radius: 4px;}
#middel_area .blue_bg{background: linear-gradient(to right, #833fcc, #0e90ff); color: #fff; padding: 15px; border-radius: 4px;}
#middel_area .blue_bg .fa-credit-card-alt {font-size: 22px;}
#middel_area .light_bg h5{ margin:0 0 4px; font-weight: 600;font-size: 16px;}
#middel_area .blue_bg h5 {color: #fff; margin: 0 0 10px; font-weight: 600; font-size: 16px;}
#middel_area .blue_bg span, #middel_area .light_bg span{font-size: 18px; font-weight: 600;}
#middel_area .blue_bg p, #middel_area .light_bg p{font-size: 14px;}
#middel_area .blue_bg a {background: rgba(0,0,0,0.2);padding: 1px 15px; display: inline-block;font-size: 12px; margin:20px 1px 4px; border-radius: 3px;}
#middel_area .blue_bg a:hover{ background: rgba(0,0,0,0.3);}
#middel_area .dash_icon {width: 45px; height: 45px; background:#0e90ff; display: inline-block;  margin: 0 0 12px; border-radius: 30px;   text-align: center; font-size: 24px; line-height: 43px;  color: #fff;  border: 1px solid #eee;}


/*********** responsive ****************/

@media(max-width:1399px){
#advance_trade .trade_right ul li { padding: 2px 5px;}
#advance_trade .trade_side_br ul li small{float:none;}
.nav_btn li a {
    padding: 6px 8px;
    margin: 9px 0 0;
    text-transform: none;
}
.navbar-brand{margin:0;}
.trade_nav .main_nav li a:first-child {
    padding-right: 0;
}
.trade_nav .navbar-nav>li a {
    padding: 8px 6px !important;
}
#advance_trade .order_list table td:last-child {
    white-space: nowrap;
}
#advance_trade .nav>li>a {
    padding: 7px 5px;
    font-size: 12px;}
}
