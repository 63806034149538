.notification-messages.info {
    background-color: #edf7fc;
}

.notification-messages .message-wrapper {
    display: inline-block;
    width: 70%;
    height: 43px;
    float: left;
}

.notification-messages .message-wrapper .heading {
    display: block;
    float: left;
    text-align: left;
    color: #1b1e24;
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    width: 100%;
    margin: 0;
    line-height: 19px;
    font-weight: 600;
}

a, a:focus, a:hover, a:active {
    outline: 0;
    text-decoration: none;
}

.notification-messages .message-wrapper .description {
    display: block;
    float: left;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    font-size: 11px;
    width: 100%;
    line-height: 19px;
}

.notification-messages .date {
    display: inline-block;
    font-size: 11px;
    line-height: 15px;
    font-style: italic;
    opacity: 0.4;
}
.pull-right {
    float: right !important;
}

.text-success {
    color: #0aa699 !important;
}
.muted {
    color: #b6bfc5;
}
.bold {
    font-weight: bold;
}

.label-success, .badge-success {
    background-color: #0aa699;
    color: #ffffff;
}

a {
    text-shadow: none !important;
    color: #0d638f;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.1s linear 0s !important;
}
.filter-div{
    display: flex;
    position: absolute;
    z-index: 2;
}
.coin-select{
    min-width: 200px;
}
.react-daterange-picker__wrapper{
    border: none !important;
}