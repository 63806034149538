/* // .customClass{
//     padding: 3px
// } */

ul.pagination li:hover {
    background: #ddd;
  }
  ul.pagination li.active {
    background: #2D8CFF;
    color: #fff;
    border-color: #2D8CFF;
  }
  ul.pagination li {
    padding: 3px;
    text-align: center;
    font-size: 12px;
    line-height: 20px;
    width: 30px;
    height: 30px;
    border: 1px #ddd solid;
  }